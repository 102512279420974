import { graphql, Link } from 'gatsby';
import { arrayOf, shape, string } from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';

import { pictureFluidType } from '../../../../types/picture';
import { Content } from '../../../common/content';
import { FluidPicture } from '../../../common/picture';
import { getLinkProps } from '../utils';
import './s.scss';

const bem = new BEMHelper({
  name: 'content-teaser-s',
});

export const TeaserS = ({
  id,
  headline,
  image: [image],
  reference,
}) => {
  const { t } = useTranslation();

  return (
    <Content id={id} {...bem()} useOwnSpacing>
      <h2 {...bem('headline')}>
        {headline}
      </h2>
      <FluidPicture
        {...image}
        maxWidth={1280}
        widths={[320, 640, 1280, 1920]}
        role="presentation"
        {...bem('image')}
      />
      <Link
        {...bem('link')}
        {...getLinkProps(reference)}
        title={reference.title ? reference.title : t('Read more about "{{ topic }}"', { topic: headline })}
      >
        <span {...bem('text')}>
          {reference.text || t('Read more')}
        </span>
      </Link>
    </Content>
  );
};

TeaserS.propTypes = {
  id: string.isRequired,
  headline: string.isRequired,
  image: arrayOf(pictureFluidType).isRequired,
  reference: shape({
    url: string,
    text: string,
    target: string,
    title: string,
    element: shape({
      slug: string.isRequired,
      departments: arrayOf(shape({
        slug: string.isRequired,
        color: string.isRequired,
      })),
    }),
  }).isRequired,
};

export const fragment = graphql`
  fragment TeaserSContentFragment on CraftGQL_pageContents_pageTeaserS_BlockType {
    id
    headline
    image {
      ...Picture
      fluid_0: url(width: 320, mode: "crop", position: "") # 0.25
      fluid_1: url(width: 640, mode: "crop", position: "") # 0.5
      fluid_2: url(width: 1280, mode: "crop", position: "") # max width
      fluid_3: url(width: 1920, mode: "crop", position: "") # 1.5
    }
    reference {
      url
      text
      target
      title
      element {
        ...on CraftGQL_pages_pages_Entry {
          __typename
          slug: pageSlug
          departments: pageDepartments {
            slug
            ...on CraftGQL_departments_department_Entry {
              color: departmentColor
            }
          }
        }

        ...on CraftGQL_departments_department_Entry {
          __typename
          slug,
          color: departmentColor
        }

        ...on CraftGQL_products_product_Entry {
          __typename
          slug
          departments: productDepartment {
            ...on CraftGQL_departments_department_Entry {
              slug
              color: departmentColor
            }
          }

          variant: productVariants(limit: 1) {
            ...on CraftGQL_variants_variants_Entry {
              slug
            }
          }
        }
      }
    }
  }
`;
